import { useContext } from "react";
import { AppContext } from "../context/appContext";
import { PostCallWithErrorResponse } from "../chatbot/api/apiservice";
import { ApiConfiq } from "../chatbot/api/apiConfig";
import { IMG_BASE_URL } from "../chatbot/api/apiConfig";





function QuestionList(props) {




    const { ques, getNext, fromAns = false, icon } = props

    const { colorSettings, userData } = useContext(AppContext)

    console.log(icon);


    const updateNoDuplicate = (id) => {

        PostCallWithErrorResponse(ApiConfiq.UPDATE_NO_DUPLICATE, {
            "questionID": `${id}-${userData?.userSession}`

        })

    }

    return (
        <div className="randam-quation " style={{ display: 'block' }}>
            <>
                <button
                    style={{ textAlign: 'start' }}
                    type="button"
                    className="btn btn-outline-danger"
                    onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                            colorSettings?.data?.primary_background_color;
                        e.currentTarget.style.color = "#fff";
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "#fff";
                        e.currentTarget.style.color = "#000";
                    }}
                    onClick={() => {
                        getNext("QUES", ques?.question)
                        updateNoDuplicate(ques?.id)
                    }}

                ><img
                        src={IMG_BASE_URL + icon}
                        onError={(e) => (e.target.style.display = "none")}
                        style={{
                            maxWidth: "25px",
                            maxHeight: "25px",
                            marginRight: "5px",
                        }}
                    />
                    {ques?.question}

                </button>
            </>
        </div>
    )

}

export default QuestionList