import { useState, useEffect, useRef, useContext } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import onlineSvg from "../assets/icons/online.svg";
import FeatureList from "./FeatureList";
import Enquirychat from "./Enquirychat";
import ChatApi from "./ChatApi";
import ErrorMsg from "./ErrorMsg";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { ApiConfiq } from "./api/apiConfig";
import { PostCallWithErrorResponse, postWithAuthCallWithErrorResponse } from "./api/apiservice";
import { LuMinimize2 } from "react-icons/lu";
import DisplayAnswers from "../component/DisplayAnswers";
import { AppContext } from "../context/appContext";

function ChatBoot() {


  const { colorSettings, setUserData } = useContext(AppContext)

  const [message, setMessage] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showChatscreen, setShowChatscreen] = useState(false);
  const [showChatApi, setShowChatApi] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isChatLoginPresent, setIsChatLoginPresent] = useState(false);
  const [userChatLive, setuserChatLive] = useState(false);
  const [isLogin, setIsLogin] = useState(
    localStorage.getItem("userId") ? true : false
  );

  const [errHandler, setErrHandler] = useState({
    passwordErr: '',
    emailErr: ''
  })
  const [isTyping, setTyping] = useState(false);

  const submitInput = useRef(null);

  function HandleChatBot() {
    setVisible(true);
  }

  // useEffect(() => {
  //   if (utm_source == "vt_text_local_sms") {
  //     HandleChatBot();
  //   }
  // }, []);



  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    let chatTrue = localStorage.getItem("ChatTrue");
    if (chatTrue == "true") {
      //setuserChatLive(true);
    }
  }, [userChatLive]);

  const [visible, setVisible] = useState(false);
  const [searchValue, setMessageValue] = useState("");

  const sendMessage = () => {
    if (searchValue.length > 0) {
      setMessage([
        ...message,
        {
          message: searchValue,
          type: "message",
        },
      ]);

      setMessageValue("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // setTyping(true)
      // alert("Test");
      event.preventDefault();
      if (searchValue) {
        if (!showForm && !showChatApi) {
          if (localStorage.getItem("chatLogin")) {
            setShowChatApi(true);
          } else {
            setShowForm(true);
            setMessageValue("");
          }
        }

        if (showChatApi) {
          if (submitInput.current) {
            submitInput.current.click();

            setMessageValue("");
          }
        }
      } else {
        setErrorMsg(true);
      }
    }
  };

  const [showHeader, setShowHeader] = useState(true);
  const [showRegisterScreen, setRegisterScreen] = useState(false);
  const [loginContinue, setLoginContue] = useState(false);
  const [question, setQuestion] = useState("");

  // sub plans

  const ChatAgentRef = useRef();

  const handleSearchMessage = () => {
    if (searchValue) {
      setQuestion(searchValue);

      setMessageValue("");
    }
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [pass, setPass] = useState("");
  const [messageCount, setMessageCount] = useState(
    localStorage.getItem("messageCount")
      ? Number(localStorage.getItem("messageCount"))
      : 0
  );

  const updateConversationByLogin = (userId, sessionId) => {
    postWithAuthCallWithErrorResponse(
      ApiConfiq.UPDATE_CONVERSATION,
      JSON.stringify({
        sender_id: sessionId,
        sender_name: userId,
      })
    ).then((res) => {
      console.log(res);
    });
  };

  const handleLoginButton = async (e) => {
    e.preventDefault();

    try {
      PostCallWithErrorResponse(ApiConfiq.LOGIN, {
        app_type: "null",
        device_id: "111",
        device_os: "web",
        device_token: "12345",
        password: password,
        user_name: username,
      }).then((res) => {
        if (res?.json?.success) {
          console.log("Login successful:", res);
          let useSes = localStorage.getItem("UserSession");

          setUserData({
            userId: res?.json?.user_id,
            customer: res?.json?.customer,
            user: res?.json?.user,
            userSession: res?.json?.user?.user_email
          })

          localStorage.setItem("userId", JSON.stringify(res?.json?.user_id));
          localStorage.setItem("customer", JSON.stringify(res?.json?.customer));
          localStorage.setItem("user", JSON.stringify(res?.json?.user));
          localStorage.setItem(
            "UserSession",
            res?.json?.user?.user_email
          );
          setLoginContue(true);
          setIsLogin(true);
          updateConversationByLogin(res?.json?.customer?.customer_email, useSes);
          handleClose();

        } else {
          setErrHandler({
            passwordErr: res?.json?.message,
            emailErr: res?.json?.message
          })
        }
      });
    } catch (err) {
      console.error("error during login:", err);
    }
  };

  const footer = () => {
    return (
      <>
        {showHeader && !showRegisterScreen ? (
          <>
            {errorMsg && <ErrorMsg />}

            {isTyping && (
              <>
                <div className="loader-container">
                  <div className="loadertime"></div>
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handlePage = () => {
    setVisible(false);
    setShowHeader(true);
    setRegisterScreen(false);
  };

  const showPlansListScreen = (plansType) => {
    setShowHeader(false);
  };

  const handleGotoRegisterScreen = () => {
    setShowHeader(true);
    setRegisterScreen(true);
  };

  useEffect(() => {
    const chatLogin = localStorage.getItem("chatLogin");
    if (chatLogin) {
      setIsChatLoginPresent(true);
    } else {
      setIsChatLoginPresent(false);
    }
  }, []);

  const endConversation = () => {
    localStorage.removeItem("ColorTheme");
    localStorage.removeItem("ChatTrue");
    localStorage.removeItem("chatLogin");
    localStorage.removeItem("chatHistory");
    setShowChatApi(false);
    setShowChatscreen(false);
    setShowForm(false);
    setIsChatLoginPresent(false);
    //  router.reload();
  };

  const [show, setShow] = useState(false);
  const [showsignup, setShowsignup] = useState(false);

  const handleClose = () => setShow(false);
  const handleClosesignup = () => setShowsignup(false);

  const handleShow = () => setShow(true);
  const handleShowsignup = () => setShowsignup(true);

  return (
    <>
      {showHeader || showRegisterScreen ? (
        <>
          {showHeader && (
            <div
              className="head service-container position-absolute"
              style={{
                backgroundColor: colorSettings?.data?.primary_background_color,
              }}
            >
              <div className="d-flex align-items-center justify-content-between text-white icons-collections">
                <div className=" row">
                  <div className="col-auto">
                    <img
                      className="chat-head-icon"
                      src={colorSettings?.data?.website_logo}
                      alt=""
                      width={60}
                      height={60}
                    />
                  </div>
                  <div className="col one_promise-col">
                    <h6 className="head-title">
                      {colorSettings?.data?.welcome_message}
                    </h6>
                    <div className="head-on">
                      Online
                      <img className="online-icon" src={onlineSvg} alt="" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <div className="d-flex">
                      <button
                        className="btn border-light text-white fw-bold"
                        onClick={handleShow}
                      >
                        Log in
                      </button>
                      <button
                        className="btn border-light text-white ms-2 fw-bold"
                      // onClick={handleShowsignup}
                      >
                        <a
                          style={{ color: "white", textDecoration: "none" }}
                          href="https://webapps.vehicletracking.qa/Register"
                        >
                          Sign up
                        </a>
                      </button>
                    </div>
                    <div
                      className="chat-bot-toggel-btn d-flex justify-content-end"
                      style={{ marginTop: "0px", marginLeft: "10px" }}
                    >

                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex justify-content-center position-relative"
                style={{ top: "-20px" }}
              >
                <div className="titleBar d-flex justify-content-around mx-auto ">
                  <div>
                    <p className="my-0">
                      <span className="make-bold">
                        {colorSettings?.data?.welcome_message}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={
              showHeader
                ? "msgBox service-container"
                : "plansListBox service-container"
            }
          >
            {/* <div className="user-name">sagar : Chat Close</div> */}
            <div className="service-container screen2">

              {showForm && (
                <Enquirychat
                  setShowHeader={setShowForm}
                  showChatScreen={setShowChatscreen}
                  handleBack={() => setShowForm(false)}
                  setShowChatApi={setShowChatApi}
                  setIsChatLoginPresent={setIsChatLoginPresent}
                />
              )}
              {(showChatApi || userChatLive) && (
                <ChatApi
                  search={searchValue}
                  forwardRef={submitInput}
                  setuserChatLive={setuserChatLive}
                  isTyping={isTyping}
                  setTyping={setTyping}
                />
              )}

              {!showForm && !showChatApi && !userChatLive && (
                <FeatureList
                  question={question}
                  handleLogin={() => setShow(true)}
                  loginContinue={loginContinue}
                  isLogin={isLogin}
                />
              )}
            </div>
          </div>
        </>
      ) : null}

      {/* chatbot icon */}
      <div className="chat-container">
        <div className="button-container">
          <img
            src={colorSettings?.data?.chat_bot_pop_logo}
            //src={ChatBotIcon.src}
            alt=""
            cursor={"pointer"}
            // onClick={() => setVisible(true)}
            width={60}
            onClick={() => {
              HandleChatBot();
            }}
          />
        </div>
      </div>

      <div>
        {/* <Modal centered show={show} onHide={handleClose} >
          <Modal.Header closeButton>
            <Modal.Title>Log in</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  autoFocus
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: "grey", border: "none" }}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{
                backgroundColor: colorSettings?.data?.primary_background_color, border:"none"
              }}
              onClick={handleClose}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal> */}
        <Modal
          centered
          show={show}
          onHide={handleClose}
          // backdrop="static"
          keyboard={false}
        >
          <div class="wrapper">
            <div class="logo">
              <img src="https://cloudxperte.crm-api.wifiattendance.com:3016/uploads/crm/website_logo-1723637440303.png" />
            </div>
            <div class="text-center mt-4 name">Vehicle Tracking</div>
            <form class="p-3 mt-3" onSubmit={handleLoginButton}>
              <div>
                <div class="form-field d-flex align-items-center">
                  <span class="far fa-user"></span>
                  <input
                    type="text"
                    name="userName"
                    id="userName"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <p className="text-danger">{errHandler.emailErr}</p>
              </div>
              <div>
                <div class="form-field d-flex align-items-center">
                  <span class="fas fa-key"></span>
                  <input
                    type="password"
                    name="password"
                    id="pwd"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <p className="text-danger">{errHandler.passwordErr}</p>
              </div>
              <button
                class="btn mt-3"
                style={{
                  backgroundColor:
                    colorSettings?.data?.primary_background_color,
                  border: "none",
                }}
                type="submit"
              >
                Login
              </button>
            </form>
            <div class="text-center fs-6">
              <div class="text-center fs-6">
                <span
                  style={{
                    color: colorSettings?.data?.primary_background_color,
                    cursor: "pointer",
                  }}
                >
                  Forgot password?
                </span>{" "}
                or{" "}
                <a
                  style={{
                    color: colorSettings?.data?.primary_background_color,
                    cursor: "pointer",
                    fontSize: "15px",
                  }}
                  href="https://webapps.vehicletracking.qa/Register"
                >
                  Sign up
                </a>
              </div>
            </div>
          </div>
        </Modal>

        <Modal centered show={showsignup}

          onHide={handleClosesignup}>
          <div class="wrapper">
            <div class="logo">
              <img src="https://cloudxperte.crm-api.wifiattendance.com:3016/uploads/crm/website_logo-1723637440303.png" />
            </div>
            <div class="text-center mt-4 name">Vehicle Tracking</div>
            <form class="p-3 mt-3">
              <div class="form-field d-flex align-items-center">
                <span class="far fa-user"></span>
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder="Username"
                />
              </div>
              <div class="form-field d-flex align-items-center">
                <span class="far fa-phone"></span>
                <input
                  type="number"
                  name="Phone"
                  id="phone"
                  placeholder="Phone"
                />
              </div>
              <div class="form-field d-flex align-items-center">
                <span class="fas fa-key"></span>
                <input
                  type="password"
                  name="password"
                  id="pwd"
                  placeholder="Password"
                />
              </div>
              <button
                class="btn mt-3"
                style={{
                  backgroundColor:
                    colorSettings?.data?.primary_background_color,
                  border: "none",
                }}
              >
                Sign up
              </button>
            </form>
            {/* <div class="text-center fs-6">
              <a href="#">Forget password?</a> or <a href="#">Sign up</a>
            </div> */}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ChatBoot;
