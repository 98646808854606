import React, { useState, useRef, useEffect, useContext } from "react";
import { InputText } from "primereact/inputtext";
import ErrorMsg from "./ErrorMsg";
import { Th } from "react-flags-select";
import { Carousel } from "react-responsive-carousel";
import { domain_id } from "./api/apiConfig";
import { blockMessage } from "./blockmessage";
import {
  PostCallWithErrorResponse,
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "./api/apiservice";
import { ApiConfiq, BASE_URL, IMG_BASE_URL } from "./api/apiConfig";
import Module from "./Modules";
import { useSearchParams } from "react-router";
import DisplayAnswers from "../component/DisplayAnswers";
import { AppContext } from "../context/appContext";
import QuestionList from "../component/questionlist";
function FeatureList({ question, handleLogin, loginContinue, isLogin }) {



  const { colorSettings, setUserData, userData } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false);

  const [isBack, setIsBack] = useState(false);

  const bottomRef = useRef(null);

  const [subModuleData, setSubModuleData] = useState({
    ilstrPic: localStorage.getItem('ilstrPic') ? localStorage.getItem('ilstrPic') : '',
    subModuleName: localStorage.getItem('subModuleName') ? localStorage.getItem('subModuleName') : '',
    icon: localStorage.getItem('icon') ? localStorage.getItem('icon') : ""
  })

  const [history, setHistory] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [isStartAgain, setIsStartAgain] = useState(false);
  const [getDataOf, setGetDataOf] = useState('')


  const [selectedSubModule, setSelectedSubModule] = useState(
    localStorage.getItem("subModule") ? localStorage.getItem("subModule") : ""
  );



  const [messageCount, setMessageCount] = useState(
    localStorage.getItem("messageCount")
      ? Number(localStorage.getItem("messageCount"))
      : 0
  );

  const [searchValue, setMessageValue] = useState("");
  let [storeRes, setStoreRes] = useState({})

  const [paramns] = useSearchParams()

  useEffect(() => {



    if (history.length > 0) {
      localStorage.setItem("conversation", JSON.stringify(history));

      if (selectedSubModule) {
        localStorage.setItem("subModule", selectedSubModule);
      }
    }
  }, [history, selectedSubModule]);

  useEffect(() => {


    if (loginContinue && isStartAgain) {


      if (history.length == 0 && isStartAgain) {
        getModules();

        return;
      }
    }

    let his = localStorage.getItem("conversation")
      ? JSON.parse(localStorage.getItem("conversation"))
      : [];

    if (his) {
      if (messageCount == colorSettings?.data?.questions_limit) {
        his?.push({
          type: "ANSWERS",
          data: "Login Successfull Continue Chat know",
          useRes: "",
          timestamp: userIp,
        });

        setHistory(his);
        getNext("SUB_MODULES", selectedSubModule);
      }
    }
  }, [loginContinue]);

  useEffect(() => {
    const ThemeData = JSON.parse(localStorage.getItem("ColorTheme"));
    console.log("Theme-data", ThemeData);

    const UserIpNo = localStorage.getItem("UserSession");
    if (UserIpNo) {
      setUserIp(UserIpNo);

    } else {
      let ip = Date.now();
      localStorage.setItem("UserSession", ip);
      setUserIp(ip);
      setUserData(prev => ({
        ...prev,
        userSession: ip
      }))
    }
  }, []);

  useEffect(() => {
    if (question) {
      if (selectedSubModule.length) {
        getNext("QUES", question);
      } else {
        const userSession = new Date().toLocaleTimeString();
        setHistory((prev) => [
          ...prev,
          {
            type: "ANSWERS",
            data: "Please Select Sub Module First",
            useRes: question,
            timestamp: userSession,
          },
        ]);

        scrollToBottom();
      }
    }
  }, []);



  const [getInnerModul, setInnerModule] = useState([]);

  const [userIp, setUserIp] = useState();

  const getModules = () => {
    getWithAuthCallWithErrorResponse(ApiConfiq.GET_MODULES + "4").then(
      (res) => {
        if (res?.json?.success) {
          console.log(res);

          let his = [
            ...history,
            {
              type: "MODULE",
              data: res?.json?.data,
            },
          ];

          setHistory(his);

          setIsLoading(false);
        }
      }
    );
  };

  const getChatBotMessage = (userName, subModule) => {

    postWithAuthCallWithErrorResponse(ApiConfiq.CHATBOT_MESSAGES, JSON.stringify({
      number: userName
    })).then((res) => {

      if (res?.json?.success) {

        setHistory([...res?.json?.chatbot_message, {
          type: 'ACTIVEMODULE',
          data: subModule
        }])

        setIsLoading(false)

        getNext('SUB_MODULES', subModule)

      }

    })

  }

  useEffect(() => {
    setIsLoading(true);

    let userId = paramns.get('userId')

    let subModule = paramns.get('subModule')

    let userName = paramns.get('userName')

    console.log(userId);

    if (userId && subModule && userName) {


      getChatBotMessage(userName, subModule)



      localStorage.setItem('UserSession', userName)

      localStorage.setItem('userId', userId)

      return

    }


    let conversation = localStorage.getItem("conversation")
      ? JSON.parse(localStorage.getItem("conversation") || [])
      : [];

    if (conversation.length > 0) {
      setIsLoading(false);
      setIsBack(true);
      console.log(conversation);

      // setHistory(conversation)
    } else {
      getModules();
    }
  }, []);

  const handleContinueChat = () => {
    let count = localStorage.getItem("messageCount");

    if (count) {
      if (count >= colorSettings.data.questions_limit && !isLogin) {
        handleLogin();
      }
    }
  };

  const handlePrevoiusConversation = (getPrev) => {
    if (getPrev) {
      setHistory(JSON.parse(localStorage.getItem("conversation")) || []);

      setSelectedSubModule(localStorage.getItem("subModule") || "");

      setIsLoading(false);

      setIsBack(false);
      handleContinueChat();

      setTimeout(() => {
        scrollToBottom();
      }, 100);
    } else {
      // setHistory(prev => [])
      setIsLoading(true);
      setIsBack(false);
      handleContinueChat();

      setHistory((prev) => []);

      setIsStartAgain(true);

      getModules()

      if (messageCount < colorSettings.data.questions_limit) {
        setTimeout(() => {
          getModules();
        }, 1000);
      } else {
        setIsLoading(false);
      }
    }
  };



  const scrollToBottom = () => {
    setTimeout(() => {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  const submitData = (data) => {

    console.log("Hellllo ======", storeRes);

    PostCallWithErrorResponse('https://phonebook.redbytes.in/api/create_email_inquiry', {
      "user_name": "N/A",
      "user_mail": storeRes['provide_email'],
      "user_location": "NA",
      "page_location": "NA",
      "country_name": "NA",
      "user_mobile": storeRes['provide_phone_number'],
      "user_message": "chat bot",
      "user_subject": "NA ",
      "inquiry_through": "web chat bot",
      "website_source": "vehicletracking.qa(chatbot)",
      "apikey": "7dac0fcac909b349"
    }).then((res) => {

      console.log(res);

    })

  }

  const addNoResQues = (ques) => {
    const data = {
      sender_id: userIp,
      question_text: ques,
      domain_id: "4",
    };
    postWithAuthCallWithErrorResponse(
      ApiConfiq.ADD_NO_RES_QUES,
      JSON.stringify(data)
    );
  };


  const handleStoreData = () => {
    if (blockMessage.includes(getDataOf)) {




      setStoreRes(prev => ({
        ...prev,
        [getDataOf]: searchValue
      }))


      console.log(storeRes);



    }


  }


  const getNext = (type, moduleName, answer = null) => {
    if (messageCount >= colorSettings?.data.questions_limit && !isLogin) {
      scrollToBottom();
      return;
    }

    if (type === "MODULE") {
      simpleGetCallWithErrorResponse(
        ApiConfiq.GET_SUB_MODULES + moduleName
      ).then((res) => {
        if (res?.json?.success) {
          let his = [
            ...history,
            {
              type: "ACTIVEMODULE",
              data: moduleName,
            },
            {
              type: "SUB_MODULES",
              data: res?.json?.data,
            },
          ];

          setSelectedModule(moduleName);
          setHistory(his);

          scrollToBottom();
        }
      });
    }

    if (type == "SUB_MODULES") {
      simpleGetCallWithErrorResponse(
        ApiConfiq.GET_QUES_LIST + moduleName + `&domain_id=${domain_id}&sendto=${userData.userSession}`
      ).then((res) => {
        if (res?.json?.success) {
          setSelectedSubModule(moduleName);



          if (answer) {
            answer.suggestionQustion = res?.json?.data
            setHistory(prev => ([
              ...prev,
              answer
            ]))
            scrollToBottom();
            return
          }

          setHistory((prev) => [
            ...prev,
            selectedSubModule != moduleName && {
              type: 'ACTIVEMODULE',
              data: moduleName
            },
            {
              type: "QUES",
              data: res?.json?.data,
              icon: subModuleData?.icon
            },

          ]);

          scrollToBottom();
        }
      });
    }

    if (type === "QUES") {
      let hist = [...history, { type: "USER_QUES", data: moduleName }];


      setHistory(hist);

      scrollToBottom();

      const url = "https://chatbot-api.cloudxperte.com/webhooks/rest/webhook";
      const data = {
        sender: localStorage.getItem("UserSession"),
        message: moduleName,
        // message: search+" "+"vehicletracking.qa",
      };

      let getInfo;
      postWithAuthCallWithErrorResponse(url, JSON.stringify(data))
        .then(async (res) => {
          console.log(res);

          if (res?.json[0]?.custom) {
            const time = new Date().toLocaleTimeString();

            getInfo = res?.json[0]?.custom?.intent

            getNext("SUB_MODULES", selectedSubModule, {
              type: "ANSWERS",
              data: res?.json[0].custom?.data,
              useRes: moduleName,
              timestamp: time,
              selectedModule: subModuleData?.subModuleName,
              icon: subModuleData?.icon,
              ilstrPic: subModuleData?.ilstrPic,
              endPointUrl: res?.json[0].custom?.intent.replaceAll('_', "/")
            });


            setGetDataOf(res?.json[0]?.custom?.intent)

            setGetDataOf(res?.json[0]?.custom?.intent)


            // setHistory((prev) => [
            //   ...prev,
            //   {
            //     type: "ANSWERS",
            //     data: res?.json[0].custom?.data,
            //     useRes: moduleName,
            //     timestamp: time,
            //     selectedModule: subModuleData?.subModuleName,
            //     icon: subModuleData?.icon,
            //     ilstrPic: subModuleData?.ilstrPic,
            //     endPointUrl: res?.json[0].custom?.intent.replaceAll('_', "/")
            //   },
            // ]);

            setMessageCount((prev) => prev + 1);

            localStorage.setItem(
              "messageCount",
              JSON.stringify(messageCount + 1)
            );

            scrollToBottom();
          } else {


            addNoResQues(moduleName);
            getNext("SUB_MODULES", selectedSubModule, {
              type: "ANSWERS",
              data: "I don't have that info. Anything else I can help with?",
              useRes: "",
              timestamp: userIp,
            })
          }
        })
        .then(() => {
          if (
            messageCount + 1 >= colorSettings.data.questions_limit &&
            !isLogin
          ) {
            setHistory((prev) => [
              ...prev,
              {
                type: "ANSWERS",
                data: "Please Login To Continue Chat",
                useRes: "",
                timestamp: userIp,
              },
            ]);

            //  setIsShowLogin(true);
            if (!isLogin) {
              handleLogin();
            }

            //  scrollToBottom();

            return;
          }

        });
    }
  };

  useEffect(() => {

    if (getDataOf === 'confirm_contact_info') {



      //  storeRes['provide_phone_number'] = question

      setTimeout(() => {
        submitData(storeRes)
      })





    }

  }, [getDataOf])

  const convertToClickableLinks = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text?.replace(
      urlPattern,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );
  };

  return (
    <div>
      {isLoading == true ? <div className="loader"></div> : <></>}
      <div
        className="d-flex service-container mobile"
        style={{ marginTop: "0px" }}
      >
        {" "}
        <div className="scroll-container">
          {isBack ? (
            <div style={{ width: "450px" }}>
              <div className="d-flex justify-content-center mt-2">
                <span
                  className="message small_font_size message-cmt"
                  style={{
                    backgroundColor:
                      colorSettings?.data?.primary_background_color,
                    color: colorSettings?.data?.secondary_text_color,
                  }}
                >
                  Welcome Back
                </span>
              </div>
              <div className="randam-quation">
                <>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor =
                        colorSettings?.data?.primary_background_color;

                      e.currentTarget.style.color = "#fff";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#fff";
                      e.currentTarget.style.color = "#000";
                    }}
                    onClick={() => handlePrevoiusConversation(true)}
                  >
                    Continue With Previous Chat
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor =
                        colorSettings?.data?.primary_background_color;
                      e.currentTarget.style.color = "#fff";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#fff";
                      e.currentTarget.style.color = "#000";
                    }}
                    onClick={() => handlePrevoiusConversation(false)}
                  >
                    Start Again{" "}
                  </button>
                </>
              </div>
            </div>
          ) : (
            history?.map((message, index) => {


              if (message.type == "MODULE" || message.type == "SUB_MODULES") {
                return (
                  <Module
                    message={message}
                    getNext={getNext}
                    colorSettings={colorSettings}
                    selectedModule={selectedModule}
                    setSubModuleData={(data) => {
                      console.log('dataaaaa', data);
                      setSubModuleData(data)
                    }}
                  />
                );
              }

              if (message.type == "ACTIVEMODULE") {
                return (
                  <div className="d-flex justify-content-end mt-2">
                    <span
                      className="message small_font_size message-cmt"
                      style={{
                        backgroundColor:
                          colorSettings?.data?.primary_background_color,
                        color: colorSettings?.data?.secondary_text_color,
                      }}
                    >
                      {message.data}
                    </span>
                  </div>
                );
              }

              if (message?.type === "USER_QUES" || message?.type_name === 'user') {
                return (
                  <div className="user-msg">
                    <div
                      className="frontend-msg"
                      style={{
                        backgroundColor:
                          colorSettings?.data?.user_message_bg_color,
                        color: colorSettings?.data?.user_message_text_color,
                      }}
                    >
                      {/* {item.useRes} */}
                      {message?.type_name === 'user' ? convertToClickableLinks(message?.message) : convertToClickableLinks(message.data)}
                    </div>
                  </div>
                );
              }

              if (message.type == "QUES") {


                return <div className="row"> <div className="col-md-6" > {message.data.map((ques, index) => {

                  return (
                    <QuestionList ques={ques} getNext={getNext} />

                  );
                })
                }
                </div>
                  <div className="col-md-6">
                    <div
                      className="card p-4 "
                      style={{
                        width: "95%",
                        border: "none",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                    >
                      <p className="mb-4">
                        <a target="_blank">https://demo.vehicletracking.qa/</a>
                      </p>
                      {/* <img
              src={IMG_BASE_URL + ilstr}
              className="img-fluid rounded"
              onError={(e) => (e.target.style.display = "none")}
            /> */}
                      <iframe
                        src={'https://demo.vehicletracking.qa/'}
                        width="100%"
                        height="500"
                        style={{ pointerEvents: "none" }}
                      //onError={(e) => e.target.src = IMG_BASE_URL + ilstr}

                      ></iframe>
                    </div>
                  </div>
                </div>
              }

              if (message.type === "ANSWERS" || message?.type_name === 'bot') {
                return (
                  <div className="chat-bot-container">

                    <DisplayAnswers
                      subModuleName={message?.selectedModule}
                      question={message?.useRes}
                      answer={message}
                      icon={message?.icon}
                      ilstr={message?.ilstrPic}
                      endUrl={message?.endPointUrl}
                      suggestionQustion={message?.suggestionQustion}
                      getNext={getNext}
                    />
                  </div>
                );
              }

            })
          )}

        </div>
      </div>

      <div className="d-flex gy-2 justify-content-between service-container slide-from-right">
        <div className="vt-scroll-container">
          <div className="data-list">
            <ul>
              {getInnerModul?.map((i, index) => (
                <li key={index}>{i.feature}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {
        !isBack &&

        <div className="d-flex justify-content-center align-items-center">
          <div
            className="input-container d-flex"
            style={{
              border: `1px solid ${colorSettings?.data?.primary_background_color}`,
            }}
          >
            <InputText
              v-model="value2"
              placeholder="Type Your Message"
              onKeyPress={(e) => {
                if (!searchValue) {
                  return;
                }

                if (e.key == "Enter") {
                  handleStoreData()
                  getNext("QUES", searchValue);

                  setMessageValue("");
                }
              }}
              variant="filled"
              className="rounded-pill input_placeholder"
              value={searchValue}
              onChange={(e) => {
                setMessageValue(e.target.value);
                // if (e.target.value) {
                //   setErrorMsg(false);
                // }
              }}
            />
            <i
              className="fas fa-paper-plane"
              onClick={() => {
                if (searchValue) {
                  handleStoreData()
                  getNext("QUES", searchValue);
                  setMessageValue("");
                }
              }}
              style={{
                fontSize: "24px",
                color: colorSettings?.data?.primary_background_color,
                cursor: "pointer",
                borderRadius: "50%",
                padding: "10px",
                backgroundColor: "#fff",
                marginRight: "15px",
              }}
            ></i>
          </div>
        </div>
      }

      <div ref={bottomRef}></div>
    </div>
  );
}

export default FeatureList;
