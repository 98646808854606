import logo from "./logo.svg";
import "./App.css";
import ChatBot from "./chatbot/chatboot";
import AppState from "./context/appstate";

function App() {
  return (
    <>
      <AppState>
        <ChatBot />
      </AppState>
    </>
  );
}

export default App;
