import { AppContext } from "./appContext";
import { useEffect, useState } from "react";



function AppState(props) {


    const [colorSettings, setColorSettings] = useState(null);
    const [userData, setUserData] = useState({

        userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : '',
        customer: localStorage.getItem('customer') ? localStorage.getItem('customer') : '',
        user: localStorage.getItem('user') ? localStorage.getItem('user') : '',
        userSession: localStorage.getItem('UserSession') ? localStorage.getItem('UserSession') : '',
    })


    useEffect(() => {
        // Define the API endpoint
        const apiUrl =
            "https://cloudxperte.crm-api.wifiattendance.com:3016/api/GetColorSettingsByDomain?domain=https://www.vehicletracking.qa";

        // Fetch the data from the API
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                setColorSettings(data);
                localStorage.setItem("ColorTheme", JSON.stringify(data));
            })
            .catch((error) => {
                // setError(error);
                //  alert("Theme API Error")
            });
    }, []);


    return (
        <AppContext.Provider value={{
            colorSettings: colorSettings,
            userData,
            setUserData
        }}
        >
            {props.children}
        </AppContext.Provider>)

}

export default AppState